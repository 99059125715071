<template>
  <section id="hero">
    <v-img
      max-height="400"
      :src="require('@/assets/banner-video.gif')"
      class="white--text"
    >
    <!-- gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)" -->
      <div 
      data-aos="zoom-in"
      data-aos-delay="50"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false"
      data-aos-anchor-placement="top-center">
      <v-container class="fill-height px-4 py-12 mt-n4">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          max-width="500"
          width="100%"
        >
          <!-- <div class="headline font-weight-bold primary--text mt-3 mb-5">DAPATKAN MANFAAT MAKSIMAL UNTUK PERLINDUNGAN HARTA BENDA DAN AKTIFITAS ANDA</div> -->

          <!-- <base-body>
            <v-tabs
              background-color="primary"
              slider-color="white"
              class="rounded"
            >
              <v-tab href="#tab-safari" class="text-capitalize">
                Safari
              </v-tab>
              <v-tab-item value="tab-safari">
                <v-text-field
                  v-model="form.name"
                  label="Nama Lengkap Anda"
                  single-line
                  class="mx-2"
                  required
                  :rules="[v => !!v || 'Mohon isi nama lengkap anda']"
                  @keyup.enter="Epolicy('safari')"
                />
                <v-btn
                color="secondary"
                tile="tile"
                class="font-weight-bold ml-2 mb-2"
                @click="Epolicy('safari')"
                >
                Hitung Takaful Safari
                </v-btn>
              </v-tab-item>
              <v-tab href="#tab-vehicle" class="text-capitalize">
                Kendaraan Bermotor
              </v-tab>
              <v-tab-item value="tab-vehicle">
                <v-text-field
                  v-model = "form.vehicle"
                  id="vehicle_reg"
                  name="name"
                  label="Plat Nomor Kendaraan Anda"
                  single-line
                  class="mx-2"
                  prepend-inner-icon="mdi-view-list"
                  @click:prepend-inner="RegisterVeh"
                  :prefix="prefix"
                  required="required"
                  @keyup.enter="Epolicy('vehicle')"
                />
                <v-btn
                color="fourth"
                tile="tile"
                class="font-weight-bold ml-2 mb-2"
                @click = "Epolicy('vehicle')"
                >
                Hitung Takaful Bermotor
                </v-btn>
                <div class="ml-2 primary--text">* Untuk mengganti plat nomor klik icon <v-icon @click="RegisterVeh">mdi-view-list</v-icon></div>
              </v-tab-item>
              <v-tab href="#tab-property" class="text-capitalize">
                Properti
              </v-tab>
              <v-tab-item value="tab-property">
                <v-text-field
                  v-model = "form.zipcode"
                  name="name"
                  label="Kode Pos Aset Properti Anda"
                  single-line
                  class="mx-2"
                  @keyup.enter="Epolicy('property')"
                />
                <v-btn
                  color="accent"
                  tile="tile"
                  class="font-weight-bold ml-2 mb-2"
                  @click = "Epolicy('property')"
                >
                Hitung Takaful Properti
              </v-btn>
              </v-tab-item>
            </v-tabs>
          </base-body> -->

        <!--   <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn>
              Discover More
            </base-btn>

            <span class="font-weight-bold ml-6 mr-4 my-4">or</span>

            <base-btn
              :ripple="false"
              class="pa-1"
              height="auto"
              text
              color="secondary"
            >
              Get Started Now
            </base-btn>
          </div> -->
        </v-responsive>
      </v-container>
      <v-bottom-sheet v-model="bottom_sheet">
        <v-list class="pt-0">
            <v-subheader class="primary white--text" @click="bottom_sheet = false">Plat Nomor <v-spacer></v-spacer><span class="cursor">tutup</span></v-subheader>
             <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <div class="scrollable">
            <v-list-item
            v-for="platnomor in platnomors"
            :key="platnomor.gendtab_id"
            @click="GetData(platnomor.gendtab_id)"
            >
            <v-list-item-title class="text-center">{{ platnomor.gendtab_id }}</v-list-item-title>
            </v-list-item>
            </div>
        </v-list>
      </v-bottom-sheet>
    </div>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',
    data: () => ({
      prefix: 'B',
      bottom_sheet: false,
      platnomors: [],
      loading: false,
      form: {
        name: '',
        vehicle: '',
        zipcode: '',
      },
    }),
    provide: {
      theme: { isDark: true },
    },
     // Check If Back Button Press, Modal Close
    beforeRouteLeave (to, from, next) {
        if (this.bottom_sheet) {
            this.bottom_sheet = false
            next(false)
        } else {
            next()
        }
    },
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'
        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    methods: {
      RegisterVeh () {
        this.loading = true
        this.bottom_sheet = true
        this.$axios.post(this.$functions.SafeURL('apiListGenTableSimple', `gendtab_actived=Y&gendtab_refid=V08&order_by=gendtab_id&order_type=ASC`))
        .then(response => {
            this.platnomors = response.data
            this.loading = false
        })
        .catch(e => {
            this.loading = false
            alert(e)
        })
      },
      GetData (id) {
        this.prefix = id
        this.bottom_sheet = false
        setTimeout(function(){
        document.getElementById('vehicle_reg').focus()
        }, 500)
      },
      Epolicy (id) {
        if (id === 'safari') {
          if (this.form.name !== '') {
            this.$router.push({name: 'polistakaful', params: { type: 'safari', id: this.form.name }})
          }
        } else if (id === 'vehicle') {
          if (this.form.vehicle !== '') {
            this.$router.push({name: 'polistakaful', params: { type: 'vehicle', id: this.prefix, data:this.form.vehicle }})
          }
        } else if (id === 'property') {
          if (this.form.zipcode !== '') {
            this.$router.push({name: 'polistakaful', params: { type: 'property', id: this.form.zipcode }})
          }
        }
      }
    }
  }
</script>
